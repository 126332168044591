<template>
    <div class="comp">
        <div class="comp-container">
            <div class="title">
                <h2> دوستان خود را به صرافی دعوت کنید، سود دریافت کنید
                    <img src="../../../assets/icons/hoora.svg" alt=""><br />
                    <div class="underlineback">
                    <div class="underline"></div>
                    </div>
                </h2>
                <p>
                    به ازای پیوستن هریک از دوستان شما به صرافی ۳۰٪ از کارمزد های ریالی و تتری او به شما تعلق خواهد<br />
                    گرفت. برای دعوت دوستان خود لازم است تا لینک دعوت خود را برای دیگران ارسال کنید و کد دعوت اختصاصی <br />
                    شما را در زمان ثبت نام وادر کنند
                </p>
            </div>
            <div class="body">
                <div class="code-container">
                    <div class="code">
                        <span>کد دعوت شما</span>
                        <div :class="{'selected':select}" @click="Copy(inviteCode,true)" class="code-wrapper">
                            <span> {{inviteCode}} </span>
                            <img src="../../../assets/icons/copy.svg" alt="">
                        </div>
                    </div>
                    <div class="code">
                        <span>لینک دعوت شما</span>
                        <div :class="{'selected':!select}" @click="Copy(inviteLink,false)" class="code-wrapper">
                            <span @click="Copy(inviteLink)"> {{inviteLink}} </span>
                            <img src="../../../assets/icons/copy.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="fri-container">
                    <div class="fri-item">
                        <img src="../../../assets/icons/friends.svg" alt="">
                        <div class="spans">
                            <span> دوستان دعوت شده </span>
                            <span> نفر{{firends}} </span>
                        </div>
                    </div>
                    <div class="fri-item">
                        <img src="../../../assets/icons/profit.svg" alt="">
                        <div class="spans">
                            <span> سود حاصل از دعوت </span>
                            <span> تومان{{$toLocal(profit)}} </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="inv-footer">
                <div class="ft-column">
                    <img src="../../../assets/icons/plane.svg" alt="">
                    <span> ۱.ارسال دعوت </span>
                </div>
                <img class="arrowsend" src="../../../assets/icons/sendarrow.svg" alt="">
                <div class="ft-column">
                    <img src="../../../assets/icons/invphone.svg" alt="">
                    <span> ۲.ثبت نام دوستان <br />
                    با دعوت شما </span>
                </div>
                <img class="rotate" src="../../../assets/icons/sendarrow.svg" alt="">
                <div class="ft-column">
                    <img src="../../../assets/icons/gift.svg" alt="">
                    <span> ۳.دریافت سود از <br /> 
                    معاملات دوستان </span>
                </div>
            </div>
        </div>
        <transition name="fade">
            <p v-if="alertLink" class="alert"> کپی شد </p>
        </transition>
    </div>
</template>

<script>
    export default {
        name:'InviteFriends',
        data() {
            return {
                select:false,
                alertLink:false,
                profit:2882920,
                firends:0,
                inviteCode:14231,
                inviteLink:'https://wallex.ir/signup?ref=YYRwO',
            }
        },
        methods: {
            Copy(e,bool) {
                this.select = bool
                navigator.clipboard.writeText(e)
                this.alertLink=true;
                setTimeout(() => {this.alertLink=false},1500)
            }
        },
        async mounted() {
            this.state.loading=true;
            const res = await this.$axios.get('/users/invites')
            this.profit=res.baseDTO.profit
            this.inviteCode=res.baseDTO.referralCode
            this.firends=res.baseDTO.countInvitees
            this.inviteLink=window.location.host+'/'+this.inviteCode
        }
    }
</script>

<style lang="scss" scoped>
.selected{
    border: 2px dashed #D09C0A;
}
.alert{
    position: absolute;
    right: 53%;
    bottom: 5%;
    // background: var(--primary-color);
    box-sizing: border-box;
    padding: 8px;
    box-shadow: 0px 0px 16px rgba(0, 119, 204, .2);
    color: rgba(64, 64, 64, .7);
    border-radius: 4px;
}
.rotate{
    transform: scaleX(-1) rotate(180deg);
}
.inv-footer{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    .ft-column{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 20px;
    }
}
.fri-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .fri-item{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 16px;
        width: 45%;
        background: white;
        border-radius: 4px;
        padding: 4px;
        .spans{
            display: flex;
            flex-direction: column;
            row-gap: 16px;
        }
    }
}
.body{
    display: flex;
    flex-direction: column;
    background: var(--nwhite-nbackground);
    border-radius: 12px;
    padding: 12px;
    row-gap: 32px;
    width: 100%;
    .code-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .code{
            width: 45%;
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            .code-wrapper{
                align-items: center;
                border-radius: 4px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding:6px 12px;
                background: var(--slow-yellow);
            }
        }
    }
}
.underlineback{
    width: 100%;
    height: 8px;
    border-radius: 12px;
    background: var(--light-yellow);
    .underline{
        float: left;
        width: 30%;
        height: 100%;
        background: var(--primary-color);
        border-radius: 12px;
    }
}
.title{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
    p{
        word-spacing: 5px;
    }
}
h2{
    font-size: clamp(30px,2vw,36px);
}
.comp-container{
    width: 48%;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding-top: 50px;
    align-items: center;
    justify-content: start;
}
.comp{
    background: url('../../../assets/inviteBG.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    min-height: 92vh;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
@media only screen and(max-width:1200px){
    .comp-container{
        width: 60%;
    }
}
@media only screen and(max-width:1000px){
    .comp-container{
        width: 80%;
    }
}
@media only screen and(max-width:800px){
    .comp-container{
        width: 100%;
    }
    .code,.fri-item{
        width: 100% !important;
    }
    .code-container{
        flex-direction: column !important;
        align-items:center ;
        justify-content: center;
    }
    .fri-container{
        flex-direction: column;
        align-items:center ;
        justify-content: center;
    }
    .body{
        align-items:center ;
        justify-content: center;
    }
    .inv-footer{
        flex-direction: column;
        row-gap: 20px;
    }
    .arrowsend,.rotate{
        display: none;
    }

}
</style>